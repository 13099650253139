import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppLayout } from 'layouts/AppLayout';

import { Delivery } from 'views/delivery';

export const AppRouter = () => {

  return (
    <BrowserRouter>
      <AppLayout>
        <Routes>
          <Route path="/" element={<Delivery />} />
        </Routes>
      </AppLayout>
    </BrowserRouter>
  );
};