import languageReducer from "./language";
import lokkersReducer from "./lokkers";
import {combineReducers} from 'redux';
import appConfigReducer from "./appConfig";

const rootReducer = combineReducers({
  language : languageReducer,
  lokkers: lokkersReducer,
  appConfig: appConfigReducer
});

export default rootReducer;