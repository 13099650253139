import React from 'react';
import { colors } from 'config/colors';

const styles = {
  container: {
    fontWeight: '300',
    fontSize: '1em',
    borderRadius: '6px',
    transition: '0.3s ease-in-out',
    padding: 12
  }
};

export const PrimaryButton = ({ text, onClick, isEnabled, color, fontColor, className }) => {
  return (
    <div
      style={{
        ...styles.container,
        background: isEnabled ? colors[color] : colors["lightGrey"],
        opacity: `${isEnabled ? '1' : '0.5'}`,
        pointerEvents: `${isEnabled ? 'auto' : 'none'}`,
        border: color !== "blue" && `1px solid ${colors?.lightGrey}`,
        color: color !== "blue" ? colors?.fontPrimary : colors[fontColor]
      }}
      onClick={() => onClick()}
      className={`my-1 ${className}`}
    >
      <span>{text}</span>
    </div>
  );
};