import React from 'react';
import { ReactComponent as WarningIcon } from 'assets/images/icons/warningIcon.svg';
import { ReactComponent as CloseIcon } from 'assets/images/icons/closeIcon.svg';
import { colors } from 'config/colors';
import { FormattedMessage } from 'react-intl';

const CustomToast = ({ text, showToast ,setShowToast,error}) => {

  return (
    <div className='bg-white m-0 p-0 w-100 h-auto d-flex align-items-center position-absolute' style={{ left: showToast ? 0 : "110%", transition: "0.5s all",borderRadius:"6px",border:`1px solid ${colors?.red}` }}>
      <div className='m-0 p-0 h-100 d-flex align-items-center justify-content-center' style={{width: "30px"}}>
        <WarningIcon className='m-0 p-0' style={{ heigth: "12.5px", width: "12.5px",stroke:error ? colors?.red : colors?.font }} />
      </div>
      <span className={`m-0 p-0 py-2 mx-2 h-fit-content ${error ? "text-danger" : ""}`} style={{fontSize:"0.8em",textAlign:"left"}}>{text ? text : <FormattedMessage id="app.error" />} </span>
      <div className='m-0 p-0 h-100 ms-auto d-flex align-items-center justify-content-center' style={{width: "30px"}} onClick={() => setShowToast(false)}>
        <CloseIcon className='m-0 p-0' style={{ heigth: "8px", width: "8px", stroke:error ? colors?.red : colors?.font }} />
      </div>
    </div>
  );
};

export default CustomToast;