const appConfigReducer = (state = { screenAvailableHeight: false }, action) => {
  switch (action.type) {
    case 'UPDATE_HEIGHT':
      return {
        ...state,
        screenAvailableHeight: action.payload
      }
    default:
      return state;
  }
};

export default appConfigReducer;