import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap'
import { TopNav } from 'components/layout/TopNav';
import { Footer } from 'components/layout/Footer';
import { colors } from 'config/colors';
import { useDispatch } from 'react-redux'
import { updateHeight } from 'redux/actions/appConfig';
import { Delivery } from 'views/delivery';

export const AppLayout = () => {

  const [safeAreaHeight, setSafeAreaHeight] = useState(window.innerHeight);
  const dispatch = useDispatch()
  const [step, setStep] = useState(1);
  const [pin, setPin] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    function handleResize() {
      const newSafeAreaTop = window.top !== window ? 0 : window.screenTop;
      const newSafeAreaHeight = window.innerHeight - newSafeAreaTop;
      setSafeAreaHeight(newSafeAreaHeight);
      dispatch(updateHeight(newSafeAreaHeight));
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Row className="p-0 m-0 overflow-hidden w-100" style={{ minHeight: `${safeAreaHeight}px`, background: colors.bgColor }}>
      <Col xs="12">
        <Row className='h-100'>
          <Col xs="12" style={{ height: 60, background: colors.white }}>
            <TopNav setStep={setStep} step={step} setPhoneNumber={setPhoneNumber} setPin={setPin} />
          </Col>
          <Col xs="12" style={{ height: "calc(100% - 95px)" }} className="overflow-hidden position-relative">
            <Delivery setStep={setStep} step={step} pin={pin} setPin={setPin} setPhoneNumber={setPhoneNumber} phoneNumber={phoneNumber}/>
          </Col>
          <Col xs="12" style={{ height: 35 }}>
            <Row className='h-100 d-flex justify-content-center align-items-center'>
              <Footer />
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};